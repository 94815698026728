// --- Content ---
$default-content-width: 994px;
$wide-content-width: 1200px;

// --- Screen Sizes ---
$ultra-hd: 3840px;
$quad-hd: 2560px;
$full-hd: 1920px;
$macbook-fifteen: 1445px;
$macbook-thirteen: 1440px;
$desktop: 1366px;
$macbook-twelve: 1280px;
$tablet-size-horizontal: 1180px;
$max-tablet-size: 1024px;
$default-tablet-size: 768px;
$max-mobile-size: 568px;
$horizontal-mobile: 450px;

// --- Element Sizes ---
$new-header-height: 88px;
