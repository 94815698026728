@import 'styles/utils/mixins';
@import 'styles/utils/sizes';

.blog {
  padding: 60px 16px 0;
  background-color: $gray0;

  .contentWrapper {
    @include flex(column);

    width: 100%;
    max-width: $wide-content-width;
    margin: 0 auto;
  }

  .titleStyle {
    margin: 0 auto 32px;

    [data-title] {
      margin: 0;
      font-weight: 600;
    }
  }

  .card-list {
    @include flex(row, center, initial);

    gap: 32px;

    & > a {
      max-width: 378px;
      flex: 0 0 100%;

      &:last-child {
        display: none;
      }

      @include macbook-twelve {
        &:last-child {
          display: block;
        }
      }
    }

    @include macbook-twelve {
      flex-wrap: wrap;
    }
  }

  .blogButton {
    padding: 16px 32px;
    margin: 48px auto 0;
    width: min-content;
    border: 1px solid $gray900;
    border-radius: 8px;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;

    @include phone {
      margin: 24px auto 0;
      width: 100%;
    }
  }

  @include tablet {
    padding: 30px 16px 0;
  }
}
